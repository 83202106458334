.icon {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  background: #93c0bd;
  color: white;
  font-size: 18px;
  width: 30px;
  height: 30px;
  padding: 10px;
  border-radius: 50%;
  margin: 10px;

  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  transition: font-size 100ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip {
  position: absolute;
  top: 0;

  font-size: 14px;
  padding: 4px 8px;
  border-radius: 8px;

  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.tooltip::before {
  position: absolute;
  bottom: -3px;
  left: 50%;
  content: "";

  background: white;
  width: 8px;
  height: 8px;

  transform: translate(-50%) rotate(45deg);
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.icon:hover {
  font-size: 20px;
}

.icon:hover .tooltip {
  top: -40px;

  opacity: 1;
  pointer-events: auto;
}

.icon:hover span,
.icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
}

.github:hover,
.github:hover .tooltip,
.github:hover .tooltip::before {
  background: #333333;
  color: white;
}

.linkedin:hover,
.linkedin:hover .tooltip,
.linkedin:hover .tooltip::before {
  background: #0077b5;
  color: white;
}

.gmail:hover,
.gmail:hover .tooltip,
.gmail:hover .tooltip::before {
  background: #c71610;
  color: white;
}

.twitter:hover,
.twitter:hover .tooltip,
.twitter:hover .tooltip::before {
  background: #1da1f2;
  color: white;
}
