.container {
  background: #03b89f;
  color: white;
  text-align: center;
  padding: 24px;
}

.title {
  font-size: 36px;
}

.countdown {
  margin-top: 16px;
}

.countdown div {
  font-size: 24px;
}
