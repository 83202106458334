/* Banner Section */
.banner {
  background: linear-gradient(
    270deg,
    #fff6e9 64.97%,
    rgba(255, 246, 233, 0.4) 100%
  );
}

.banner :global(.ant-row) {
  font-size: 16px;
  font-weight: 500;
}

.separator {
  border: 1px solid #4a3e2f;
  width: 21px;
  height: 0px;
}

.bannerPhoneSection {
  display: grid;
  place-content: center;
  height: 100vh;
}

.bannerPhoneImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

@media only screen and (max-width: 1199px) {
  .bannerPhoneImg {
    width: fit-content;
    height: fit-content;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  .bannerPhoneImg {
    -webkit-transition: transform 0.2s ease 0s;
    transition: transform 0.2s ease 0s, scale 0.2s;
  }

  .bannerPhoneImg:hover {
    transform: rotate(1deg) rotateX(3deg) rotateY(-7deg) translateZ(0px);
    scale: 1.05;
  }
}

.scrollBtn {
  position: absolute;
  z-index: 98;
  width: 100%;
  text-align: center;
  bottom: 60px;
}

.scrollBtnInner {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;

  background: linear-gradient(180deg, #397672f2 0%, #63908dcc 100%);
  color: #bde3e1;
  padding: 8px;
  border-radius: 20px;

  -webkit-transition: transform 0.2s ease 0s, scale 0.2s;
  transition: transform 0.2s ease 0s, scale 0.2s;
}

.scrollBtnInner:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  scale: 1.05;
}

/* Carousel Section */
.carouselBg {
  background: linear-gradient(180deg, #397672 0%, #63908d 100%);
  height: 100vh;
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 1199px) {
  .carouselBg {
    height: auto;
  }
}

.carouselBg :global(.boxContainer),
.carouselBg :global(.boxContainer) > div,
.carouselBg :global(.boxContainer) > div > div {
  height: 100%;
}

.carouselInner {
  height: 100%;
}

.carousel_arrow {
  border-radius: 100%;
  background: #63908d;
  display: grid;
  place-content: center;
  z-index: 2;
}

.carousel_arrow img {
  aspect-ratio: 1;
  padding: 10px;
}

@media only screen and (max-width: 767px) {
  .carousel_arrow img {
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.carousel_arrow:hover img {
  filter: brightness(0.8);
}

.carouselItem_max {
  display: grid;
  place-content: center;
  padding: 0 16px;
}

/* Footer */
.footerBtn {
  border: 1px solid transparent;
  border-radius: 5px;

  -webkit-transition: transform 0.2s;
  transition: transform 0.2s;
}

.footerBtn:hover {
  border: 1px solid #63908d;

  transform: translateY(-2px);
}

.wrapper {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .footerContainer:global(.boxContainer) {
    padding: 0px;
  }
}
