/* Card */
.cardBg {
  background: #fff6e9;
  border-radius: 4px;
}

.tag {
  background: #93c0bd;
  border-radius: 4px 0px 0px 4px;
  width: 4px;
  height: 100%;
  position: relative;
}

.cardInfo {
  width: calc(100% - 4px);
  padding: 32px 32px;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  margin-right: 6px;
}

.gameIcon {
  background: #678785;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.25);
}

.smIcon {
}

.rowSeparator {
  margin-left: auto;
  margin-right: auto;
}

.separator {
  background: #93c0bd;
  width: 2px;
  height: 24px;
}

.dateTimeSeparator {
  background: #93c0bd;
  width: 1px;
  height: 15px;
}

/* Text */
.title {
  color: #93c0bd;
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.desc {
  color: #93c0bd;
  text-align: right;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.id {
  color: #678785;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
}

.activeId {
  color: #93c0bd;
  cursor: pointer;
}

.idLabel {
  color: #788a9f;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.dateTime {
  color: #788a9f;
  font-family: Open Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;

  text-align: right;
}

@media only screen and (max-width: 767px) {
  .cardInfo {
    padding: 8px 16px;
  }

  /* Text */
  .blockId {
    font-size: 12px;
    line-height: 19px;
    white-space: nowrap;
  }

  .hashLabel {
    font-size: 10px;
    line-height: 19px;
    white-space: nowrap;
  }

  .hash {
    font-size: 10px;
    line-height: 19px;
    white-space: nowrap;
  }

  .amount {
    font-size: 12px;
    line-height: 19px;
  }

  .coin {
    font-size: 12px;
    line-height: 18px;
  }

  .separator {
    height: 19px;
  }

  .dateTime {
    text-align: left;
  }
}
