.tag {
  padding: 6px;
  border-radius: 8px;
  color: white;
}

.friendTag {
  background: #93c0bd;
}

.commissionTag {
  background: #d3bfa7;
}

.twitterTag {
  background: #1da1f2;
  cursor: pointer;
}

.igTag {
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  cursor: pointer;
}

.skebTag {
  background: #30b396;
  cursor: pointer;
}
