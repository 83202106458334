.step
  :global(
    .ant-steps-item-active
      .ant-steps-item-container
      .ant-steps-item-content
      > .ant-steps-item-title
  ) {
  color: #517371;
}

.step
  :global(
    .ant-steps-item-active .ant-steps-item-container .ant-steps-item-icon svg
  ),
.step
  :global(
    .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon svg
  ) {
  color: #517371;
}

.step
  :global(
    .ant-steps-item-finish
    .ant-steps-item-container
      > .ant-steps-item-content
      > .ant-steps-item-title::after
  ) {
  background-color: #517371;
}

.contentContainer {
  min-height: 260px;
  text-align: center;

  padding: 32px;
  border-radius: 8px;
  background: #f6f6f9;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  color: #282838;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

/* Education */
.educationItemWrapper {
  text-align: left;

  padding: 32px;
  border-radius: 8px;
  background: #ffffff;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.08);
}

.educationYear {
  color: #827f9e;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.educationTitle {
  color: #282838;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.educationDesc {
  color: #827f9e;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.timeline :global(.ant-timeline-item-head-custom) {
  background: transparent;
}

.timeline :global(.ant-timeline-item-head-custom svg) {
  fill: #517371;
  width: 30px;
  height: 30px;
  margin-top: 16px;
}

@media only screen and (max-width: 767px) {
  .contentContainer {
    padding: 16px;
  }
  .educationItemWrapper {
    padding: 16px;
  }
}
