@import "core/theme.css";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans");

body {
  font-family: "Poppins", "Open Sans", sans-serif !important;
}

/* ------------------------ container with max width ------------------------ */
.boxContainer {
  margin: 0px auto;
}

.boxContainer .boxContainer {
  padding-left: 0;
  padding-right: 0;
}

@media only screen and (min-width: 1600px) {
  /* >= xxl */
  .boxContainer {
    max-width: 1400px;
  }
}

@media only screen and (max-width: 1600px) {
  /* < xxl */
  .boxContainer {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 1248px) {
  .boxContainer {
    padding: 0 24px;
  }
}

@media only screen and (max-width: 767px) {
  .boxContainer {
    padding: 0 16px;
  }
}

@media only screen and (max-width: 767px) {
  .ant-layout-header {
    padding-inline: 12px;
  }

  .ant-layout-footer {
    padding: 24px 24px;
  }
}

.footer-items:hover {
color: #63908D;
}

.pageTitle {
  font-family: Poppins;
  font-size: 48px;
  font-weight: 700;
}
